<template>
  <div class="section1">
 <!-- <img src="./s1/0.jpg" :alt="`${info.caseName}_bg`" class="img0">  -->
    <img src="./s1/bg.png" :alt="`${info.caseName}_b`" class="bg" v-if="isPC">
    <img src="./s1/bgm.png" :alt="`${info.caseName}_b`" class="bg" v-if="isMobile">
    <img src="./s1/b.png" data-aos="zoom-in-up" data-aos-delay="300" :alt="`${info.caseName}_b`" class="b" v-if="isPC">
    <img src="./s1/bm.png" data-aos="zoom-in-up" data-aos-delay="300" :alt="`${info.caseName}_b`" class="b" v-if="isMobile">
    <img src="./s1/m.png" data-aos="zoom-in-up" :alt="`${info.caseName}_b`" class="m">
	<div data-aos="fade" data-aos-delay="300" class="tree">
  	  <img src="./s1/tree.png" :alt="`${info.caseName}_tree`">
    </div>
	<div data-aos="fade" data-aos-delay="300" class="tree2" v-if="isMobile">
  	  <img src="./s1/tree.png" :alt="`${info.caseName}_tree`">
    </div>
<svg version="1.1" data-aos="fade" data-aos-delay="300" id="logo" class="logo" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 700 570" xml:space="preserve">

	<defs>
		<path id="SVGID_1_" d="M88.3,314.2c-1.6-3.7,7.3-5.9,11.5-7.4c7.9-3,15.4-7.5,23.7-9.1c4.2-1.2,9.9-6.7,13-1.1
			c3.3,4.7,6.9,9.4,10.1,14.2c6.4,11.5,19.3,15.9,28.3,4.4c6.4-6.8,3.1-17.4,3.8-25.9c-5.2-19.9-38.2-35.7-57.6-31.7
			c-0.3,4.7,5.8,15.6-0.2,17.4c-16.9,4.8-34.5,5.9-51.7,9.6c-4.6,0.4-18.7,6.1-15.3-3.3c4.5-9.2,9.5-18.3,16.5-25.8
			c8.9-12.8,15.6-8.7,29.8-11.7c10.3-0.9,20.3-4.2,30.8-4.7c8.1,0.1,15.2-4.1,23.2-1.8c7.9,1.6,15.1-3.2,15.9-9.8
			c1.5-24.5-29.3-30.6-47.8-34.8c-8.5,0.8-16.6,4.5-24.8,6.8c-5.4,1.1-10.4,2.9-14.6,6.3c-9,6.8-20.4,7.8-30.8,11.1
			c-11.1,3.3-22.1,7.1-33.9,7.1c-3.3,0.4-7.2,1-5.1,5.3c13.7,27.7,44,7.5,20.4,32.9c-7.2,8-10.6,26.9-23.6,25.8
			c-3.7,0.9-4.6,6.2-7.3,8.5c-3.2,3.5-3.1,6.3-1,9.2c3.7,4.8,4.1,11.8,10.3,14.6c1.1,0.5,2.3,1.6,2.6,2.7c1.3,4.2,4.7,6.8,7.3,9.9
			c1.8,1.5,5.2,0.2,7.3,0.9c7.2,2.2,18.6,4,23.3-3c2.1-4.4,7.1-4.3,9.5-7.6c1.2-0.2,2.6,0.1,3.8,0.7C67.3,320,73.3,316.7,88.3,314.2
			z"/>
	</defs>
	<clipPath id="svg9">
		<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
	</clipPath>
	<defs>
		<path id="svg8" d="M72.2,396.3c-13.5,3.7-45.3-22.7-45.8-36.1
			c-1-5.4,13.7-1.6,17.4-3.1c4.3-0.5,8.5-1.9,12.8-2.8c11-2.1,16.7-2,17.9-5c12.5-4.7,25.7-7.8,40.7-5.9c0.5,1.2,1,0.9,2.4,1.3
			c10.2,3,20.9,4.5,30.1,10.1c2.2,1.6,11.7,9.5,4.8,10.3c-18.9,0.1-9.1,7.3-23.1,8.9c-5.4,1.8-15.4-0.3-14.9,8.3
			C106.8,381.6,95.4,389,72.2,396.3z"/>
	</defs>
	<clipPath id="svg7">
		<use xlink:href="#svg8"  style="overflow:visible;"/>
	</clipPath>
	<defs>
		<path id="svg6" d="M114.8,405.2c5.3-1.6,17.2,0.6,15.8-7.9
			c12.4,0.1,12.8-6.9,23.6-2.9c12.5,3.4,20.7,13.9,31,20.8c5,2.8,4.5,9.2,5.8,14.1c0.4,2.9-1.8,4.7-3.7,6.5
			c-0.3,0.3-1.1,0.4-1.4,0.2c-5.4-3.8-9,5.2-16,0.5c-9.6-3.9-19.9-3.5-30.2-4c-26.1-1.1-52,0-77.7,4.8
			c-19.3,8.7-48.3,10.6-58.4-12.2c16.4,0.5,32.2-3.9,48.1-7.2c4.1-1.5,8.7-0.8,12.6-3.8c2.9-2.3,11.1,0.8,12.5-3.7
			C91.6,408.4,104.3,406.7,114.8,405.2z"/>
	</defs>
	<clipPath id="svg5">
		<use xlink:href="#svg6"  style="overflow:visible;"/>
	</clipPath>
	<defs>
		<path id="svg4" d="M66,323.7c1.5,0.8,2.7,2.1,3.2,3.8
			c2.5,11.6,6.6,18.5,5.3,21.9c3.6,13.9,4.6,28.8-2.2,47c8.1-2.3,5.5,9.9,4.5,14.1c-2.3,9.6,0.5,41.1,38.1-5.2
			c-3,0.3-4.5-2.2-3.4-5.1c2.4-7.2,3.1-12.3,3.1-17.9c-2.6-13.4-2.6-26.4,0.7-38.9c-4.8-22-5.8-14.8-23.1-25.9
			c-2.2-1.2-3.4-2.4-3.8-3.4C70.8,308,66.8,314.4,66,323.7z"/>
	</defs>
	<clipPath id="svg3">
		<use xlink:href="#svg4"  style="overflow:visible;"/>
	</clipPath>
	<defs>
		<path id="xsvg2" d="M356.8,249.1c-8.3-1.9-33.2-3.9-20.6,9.9
			c0.5,0.6,1.8,1.1,2.6,1c6.5-1,11.5,2.4,16.7,5.1c3,1.6,2.1,4.9,1.2,7.5c0,8.5-23.5,0.8-25.6,12.7c-3.2,2.6-4,11.1,2.3,8.3
			c9.9-6.2,21.9-2,32.2-5.6c6.3-0.3,20.9,5.5,18.1,13.7c-0.4,4,0.6,8.2-5.4,7.7c-20.8-1.9-20,12.4-1.6,16.2
			c11.4,2.3,46.7,14.2,38.1,29.6c-3.5,1.4-8.1-0.5-11.9-0.5c-5.3-4.8-6-1.1-11.4,0c-10.8-0.2-21.6-0.6-32.3-0.9c-1.7,0-6.6,4-7,5.5
			c-0.5,1.8,0.8,3.8,3,4c13.4-0.7,53.9,34.8,26.4,40.7c-6.3,7.2-15,11.8-20.2,19.8c-3.6,4.2,2.7,8.4,4.1,12.3c1,1.8,0.5,2.8-1.7,3.8
			c-3,1.3-4.9,5.6-8.4,6.1c-13.4-0.2-27,1.4-40.2-1.9c-12.3-0.8-24.2,3.1-36.5,1.8c-19.5-11.4-53.1-36.9-28.5-59.4
			c2.2-6.6,11.7-8,15-1.9c2.9,3,5,9.9,10,5.7c5.6-3.5,11.2-7,17-10.2c7.4-4.1,16.8-6.2,21.5-13.9c12.1-15.1-2.7-10-12.6-8.7
			c-3.7,0.6-4.6,4.6-6.8,7.1c-4.3,4.9-10.2,7.5-15.7,11.4c-1.9-3.9-2-13.2-8.5-11c-8.6,3.4-17.9,5.7-26,10.1
			c-7.7,2.5-16.2-4-23.8-5.7c-4-1-7.9-2.9-10.6-6c17.1-8.1,34.5-12.9,52.3-18.4c5.9-1.9,10.8-1.7,9.3-9.5c-0.2-6.7-9.8-6.3-13.9-9.7
			c-3.8-3-9.5-5.6-10.2-10.8c6.6-1.8,26.7-5.2,28.4-11.6c-6-6.5-17.2-8-23.8-14.4c-3.1-2.1-7.6-10.6-1.2-11.1
			c8.6-0.2,16.5-3.7,24.5-6.2c4.4-1.3,3.7-5.5-0.2-6.9c-11.4-3.8-21.3-10.8-28.4-20.4c-1.8-2.3-0.9-3.8,2.1-4.3
			c27.4-9.2,21.9-5.4,14.9-29c-0.3-4.2-6.2-12.5-1.3-15c5.5-1.6,12.4-11.8,17.9-6.7c6,6.6,5.1,9.8,14.9,7
			c-4.4,7.6-4.5,15.4-5.1,23.8c-1.4,6.2,17.7,1.9,19.6-1c7-7.5,13.3-16.5,22.1-21.9c4.7-3.2,18.5-8.5,11.5-15.1
			c16.2-6.5,43.7,27.8,30.5,36.6c-4.5,2.1-21.4-1-15.3,8c1.3,1.3,1.7,5.5,1,7.2c-0.9,2.7-1.7,5.8-2,8.5
			C355.6,244.9,356.1,246.9,356.8,249.1z M323.9,381.6c-11.2-0.1-20.4,3.9-30.2,8.2c-21.1,12.1-15.1,6.2-9,25.2
			c0.6,6.9,11.2,1.2,16,2.4c18.3,1.1,28.6-16.7,38.2-29.5C342.9,380.6,327.1,383.1,323.9,381.6z M334.3,318
			c-11.1,3.4-24.3,5.5-34,11.5c-0.6,2.5-0.1,6.3,3.4,5.1c2.8-2.2,2.6-0.4,2.9,1.6c1.4,7.8,14.5-0.6,19.6-0.7c3.5-0.8,7.6,0,8.4-5.1
			C334.4,326.6,341.3,318.5,334.3,318z"/>
	</defs>
	<clipPath id="xsvg1">
		<use xlink:href="#xsvg2"  style="overflow:visible;"/>
	</clipPath>
	<defs>
		<path id="svg2" d="M613.7,294c-12.4,0.3-38,4.5-43.6,16.7
			c-2.1,4.4-9.2,10.7-2.1,14c9.9,4.5,20.8,9.4,25.6,19.9c6.7,17.9-15.3,5.8-13.9,45.3c-0.4,2.9-2.8,5.5-0.5,8.4
			c4.2,17.2,61.6,34.2,63.6,8.7c0-7.2,1.9-14.5,0.4-21.7c-0.7-3.3,3.1-4.4,4.6-1.7c2.4,4.1,3.3,4.4,7,1.2c5.5,5.3,12,9.7,18.2,14.3
			c7.5,8.9,15.7,17.7,23.7,26.2c3.9,1.9,4.3,8.6,0.5,10.6c-8.4,9.3-21.3,10.8-33.1,10.5c-46,1.6-118.9-11.6-116.2-70.5
			c1.6-1.3,1.6-3,1.2-4.6c-1.8-7.2,1.6-13.8,1.5-20.9c-0.9-5.6-7.7-1.9-8.4,1.8c-1.4,6.9-10.3,9.4-10.8,16.4
			c-0.5,4.6-8.5,2.9-8.9,9.5c-2.6,8.4-11.6,13.8-16.4,21.1c0,5.5-9,7.2-10.1,12.9c-2.3,4.1-7.8,7.4-10.8,11.3
			c-6.7,6.9-7.4,13.8-18.5,13.7c-4.8,2.5-6.2,0.5-10.2-1.4c-7.7-1.9-26.3-20.2-12.9-24.7c8.3,0.8,17.8-2.4,24.1-7.7
			c2.6-2.5,4.7-5.6,8.5-6.7c0.9-0.2,1.8-1.4,2.1-2.3c2.1-4.8,9.1-6.5,10.9-11.8c5.9-2.2,6.6-8.7,10.8-12.5
			c11.7-10.4,19.3-25,30.9-35.4c0.6-0.5,1.6-1.1,1.7-1.8c1.1-4.8,4.1-8.4,7.4-11.8c3.5-3,0.2-7.8-3.9-5.8c-3,0.9-5.6,2.2-8,4.3
			c-9.1,4.5-18.6,8.9-22.9,18.8c-15.8,24.7-42-4.2-56.7-15.8c-5.2-13.8,1.3-41.4,20-27.4c10.2,4.5,27-6.9,37.8-9.3
			c11.3-5.5,23.5-6,35.1-10c2.1-1.3,2-3.5,5.1-3.7c11.2-3.4,22.6-6.1,34.1-8.4c11.6-7.2,27,0.1,33.7,11
			C614.6,281.3,610.5,287.4,613.7,294z M519.7,261.1c7.9-1.3,14.8-6.2,22.5-7.4c15.3,2,28.9-4.5,42.3-10.9
			c6.8-2.4,18.5,0.9,22.3-6.2c8.3-6.6,10.5-2.6,10.2-16.2c-0.5-6.9-10.8-9.9-15.4-14.3c-26.7-21.6-54.8,4-83.8-9.4
			c-1.1-0.3-3-0.5-3.6,0.2c-17.2,14.5-1.5,31.4,10.2,43.3c8.1,6-4.4,13.2-5.4,20C519.1,260.5,519.4,260.8,519.7,261.1z"/>
	</defs>
	<clipPath id="svg1">
		<use xlink:href="#svg2"  style="overflow:visible;"/>
	</clipPath>
<path  data-aos="fade" data-aos-delay="1600" class="t1" d="M255.5,13.9c4.4-0.4,13.9-2.6,16,3.6c0.7,4,1.1,10.5-2.8,12.5c-2.7,2.6-5.8,2.1-10.1,2.3c-7.5,1.5-19.9,1.7-21.4,12.4
	c-1.7,6.8,21-7.7,25.4-5.2c6.6,0.6,4.8,5.5,11.3,8.6c7.5,13-39.6,3.4-20,12.5c14.4,3.7,25.7-14.3,32.4,7.7c0.9,2.2,0,3.6-1.9,5.6
	c-1.5,3-11-0.3-7,2.8c15.7,12.9,42.5,19.8,60.2,24.3c1.3,0.9-0.1,1.6-0.4,3.6c-0.3,2-0.3,1.3-1.1,3.2s-1.1,0.7-2,1.3
	c-2.8,2.1-5.4,4.7-8.1,7c-1.2,0.8-7.7,3.4-10.1,4.7c-3.8,2.1-6.8-2.2-10.3-2.2c-14.4-12.9-32.2-28.9-45.3-42.7c-1.2-0.8-1.2-1.9-2-2
	c-0.8-0.1-6.3,0.1-8.3,0.4c-0.9,0.6-26.8,3-11.6,5c10.2,4.3,9.9,8.8,5.2,18c-1.1,6.5,1.3,12.2,2,19.1c0.4,4-0.1,21.2-0.1,23.7
	c-0.5,4.7,0.4,25.2-1.9,29.6c-0.8,1.3-3.2,0.9-4.6,2.3c-1.3,1.3-1.5,0.1-2.6-0.5c-3.6-0.7-9.2-10.5-8.7-14.5c-0.9-3-4.4-2.8-4.7-7.3
	c-0.3-1.5,2.8-0.7,4.7-1.1c4.5-3.1,5.8-14,7.1-22c0.8-16.9,0.8-27.5-1.2-41.1c-0.2-4.3-5.1-3.4-3.4-5.8c1.1-1.2-1.5-0.9-2.1-0.5
	c-10.5,1.5-6.3,4.5-10.6,6.2c-2.2,1.8-3.5,12.9-4.7,14.1c7,1.5,15.7,12.3,8.7,19.6c-1.7,3.3-9.9-7.2-11.3-9.3
	c-2.6-4.3-5,5.9-6.9,7.5c-9,15-28.7,29.3-42.9,39c-1.2,0.5-3.6-0.3-4.2,0.4c-0.5,0.7-1.1,0.4-3.8,0.4c-6.4,0.8-3-0.7-0.8-2.6
	c1-4.4,9.7-7.9,13.8-12.4c8.9-10.6,33-39.7,34.4-51.1c-6.2-2.7-24.5-13.7-5.5-14.9c2.8-0.7,11.6-3.6,14-3.8c2.4-0.1,1.9-1.7,2.7-3.1
	c1-5.1-4.2-4.2-9.9-7.8c-3.5-1.7-0.3-3,1.7-4.6c10-5,17.5,1.1,16.5-15.9c-2.7-4-8.2-4.9-7.3-12.6c-0.3-7.6,12.2-2.2,13.6-6.8
	c0.2-0.7,0.2-1.5-0.2-2.6c-6.2-12.5-10-13.8,7.5-12.9c4.1,0.8,6,2.6,10.1,3.1c1.9,0.5,0.7,0.8,1.7,2.6s1.3,2.8,1.6,3.8
	C248.8,17.1,252.7,14,255.5,13.9z M205.5,138.7c-3.1,3.3-8,3.2-5.9,9.4c0.6,2.6,4,11.8,8.5,12.4c5,1.8,6.6-6.1,6.7-9.7
	c0.4-2.6,0.4-1.2,1.6-3.2c1.7-2.2,0.8-4.8,1.5-6.9c2.9-4.5,5.7-10.9,8.5-15.5c3.2-3.7-0.2-4-2.3-2.3
	C218.5,127.7,211.1,133.4,205.5,138.7z M262.3,119.2c-1.4,2.6-1.1,5.5-0.5,8.2c1.2,2.6-2,3.2,0,5.5c3.5,4.1,17.4,23.2,19.2,25.9
	c3.9,2.9,11.8,9,17.3,9.5c2,0.3,1.1-2.4,1.3-3.8c6.6-13.6-8.5-26.8-20.4-28.9c-1.6-0.3-1.6-1.7-3.5-3c-1.9-1.2-1.3-0.1-3,0.3
	c-1.6,0.4-1.6,0-1.9-1.1s0.8-1.2,2-2.4c1.2-1.2,1.7-2.7,2.8-5.1c1.7-4.3,3.8-7,3.1-10.9c-0.4-0.5-0.7-4.7-2.2-7
	c-2.1-4-5.6-2-5.4,0.7C271.2,109.1,264.6,117.5,262.3,119.2z"/>
<path data-aos="fade" data-aos-delay="1600" class="t2" d="M541.4,78.1c-3.2-1.3-19.6-6.6-23.6-7.9c-22.1-9.6-44.6-29.3-66-40.5c-1.9-1,0.1-3.1,1.3-3.9c2.8-4.4-0.9-13.7-3.2-18.4
	c-4-4-16.7-2.7-20,0.9c-1.3,5.9-12,6.4-3.7,12.1c2.8,2.3,0.7,0.4,0.7,2c0,1.6,0,0-0.3,1c-8.3,22.8-26.6,44.6-42.1,62.9
	c-1.2,1.3-0.6,1.3-1,3.1c-1.8,3.5-6.4,4-7.6,8.3c-2.7,3.8-5.3,1.9-4.1,7.2c-0.4,1.6-3.5,3.2-5.3,5.4c-3.6,3.9-1.3,3.2-5.7,5.3
	c-1.6,0.1-0.1,0.7-0.7,2.2c-1.6,3.6-6.4,4.9-5.9,8.3c0.6,1.5-1,1.5-1,1.5c1.9,1.4-3.6,7,2,2.8c2.4-1.6-0.8,5.5,4.8,0.9
	c6.8-3.5,14.2-12.3,21.1-15.8c1.9-1.5,8.8-11.3,10.2-13.3c3.4-4.6,9.3-9.9,13.5-13.5c1.3-1.5,8-12.1,10.7-15.7
	c2.6-3.5,1.9,0.1,2.2,1.3c1.9,5.3,5.9,14.4,13.5,11.6c6.3-1.3,5.4-4.4,5.9,4.8c-0.6,3-7.9,2.9-11.1,3.2c-7.2,0.7-5.2,12.8-1,16.1
	c1.5,0.7,6.7,0.1,9.4-0.1c2.6-0.3,2,0.1,2.3,1.3c-0.8,14.6,9,10-6,17.6c-4.9-0.1-5.9-20.2-13.8,1.9c-3.1,3.8-3.2,5.2-6.4,11.6
	c-5,9.2,7.9,29,17.3,28.8c3.3,1.3,0.2-8.1,1.8-9.7c11.8-2.9,35-0.2,48.1-1.2c3.8-0.7,0.4-4.4-0.3-5.9c-1.8-4.2,2.7-8.5,4.2-13.5
	c1.2-2.8,0.7-3.7,2.5-4.2c3.2-1.1,8.1-4.6,4.5-7.9c-0.9-1.2,0.7-1.2,1.2-2c0.4-0.9-7.8-7.5-10.4-8.3c-8.4-4.4-24.2,5.7-29.1,0.4
	c1.8-5.3-3.2-15.3,7.2-12.7c3.7,0,2.9,0.1,4-1.2c1-1.3,1.8-0.9,2.6-1c2.2-0.7,10.4,3.6,6.1-3.7c-1.8-3.6-4.7-13-10.2-12.6
	c-4.4-1-9.9,6.1-9.9-2c-3.7-6.8,3-2.4,5.4-5.4c0.5-1.1,3.8,1.1,4.5,0c1-0.7,1-0.4,1.9-0.6c2.8,3.2,10,0.7,15.2,0
	c3.7-0.7,2.6-0.9,3.2-2.8c1.3-3.2-2.4-4.8,0.9-6.9c1.2-1-2.6-1.9-4.7-2.8c-6.3-4.3,4.4-8.7-16.1-5.1c-2.6,0.2-42.3,10.7-38.6,3.8
	c1.8-1,19.6-26.2,20.6-27.9c3.1-3.5,3.1-0.9,7.2,1.3c8.8,6.4,28.3,22.2,36,29.1c3.2,3.6,0.5,6.8,7.6,10.2c7.4,4.9,15.8,17.6,26,18.6
	c8,1.9,11-5,17.8-4.2c3.1,0.1,2.3-0.6,4-1.6c1.9-1.9,6.7,2.1,7-1.6C555.5,87.2,546,80.6,541.4,78.1z M442.7,131.2
	c7.9,5.8,21.5-0.4,27.2,5.1c-8.3,9.5-4,16.2-6.6,17.1c-4.1-0.2-24.9,2-30.4,4.5c-2.5,0.3-5,1.2-5-0.7c0-4.9-0.1-16.1,0.1-20.2
	c-0.1-0.6-1.8-2.5-0.3-2.8C432.2,134.2,437.5,129.5,442.7,131.2z"
/>
<g class="l">	
<g class="st1">	
		<path style="stroke-width:50;" class="an1" d="M30.9,220.6c70.8,14.1,78.4-19.3,122.9,5.5"/>
		<path style="stroke-width:33;" class="an2" d="M73.2,239.3c-27.9,18.5-30.4,38.6-37.3,50"/>
		<path style="stroke-width:41;" class="an3" d="M19.9,297.3c24.3,56.5,3.6,4.7,99.3-16.3"/>
		<path style="stroke-width:44;" class="an4" d="M130.8,261.1c11.2,7.5,30.3,26.4,30.3,46.6"/>
</g>
<g class="st2">
		<path style="stroke-width:46;" class="an5" d="M41.7,368c29.5,25.1,58-22.3,96.8-6.2"/>
</g>
<g class="st3">
		<path style="stroke-width:42;" class="an6" d="M15.6,429.8c109.2,2.3,134.6-31.6,158.5-8.3"/>
</g>
<g class="st4">
		<path style="stroke-width:47;" class="an7" d="M82.6,323.3c25,30.1,12.9,72.1,9.7,85"/>
</g>
<g class="st5">
		<path style="stroke-width:35;" class="an8" d="M278.7,196.6c-2.4,6.9-3.5,15.9-3.5,20.5"/>
		<path style="stroke-width:37;" class="an9" d="M359.2,186.6c23.1,30.5-9.2,9.9-38.8,20.9"/>
		<path style="stroke-width:37;" class="an10" d="M261,244c35.5,11.9,48.7-24.3,91.7-9.7"/>
		<path style="stroke-width:28;" class="an11" d="M256.5,285c29.4,6.4,29.8-8.6,50.3-8.6"/>
		<path style="stroke-width:23;" class="an12" d="M303.6,276.4c10.1-3.3,23.3-6,42.7-5.9"/>
		<path style="stroke-width:27;" class="an13" d="M258.6,319.3c26.7,7.8,90.4-25.2,118.8-17.7"/>
		<path style="stroke-width:62;" class="an14" d="M302.5,244.9c10.5,16.1-3.9,40.1-0.4,51.8"/>
		<path style="stroke-width:31;" class="an15" d="M284.8,328.7c3.1,6.8,6.4,25.1,0.4,34.3"/>
		<path style="stroke-width:30;" class="an16" d="M349.7,318c4.1,9.9-6.8,39.3-20.1,45"/>
		<path style="stroke-width:34;" class="an17" d="M223.4,365.4c22.3,3.7,126.3-47.4,185.1-19.5"/>
		<path style="stroke-width:39;" class="an18" d="M253.1,396.7c6.1,13.1,13,18.9,15.8,27"/>
		<path style="stroke-width:43;" class="an19" d="M271.9,382.5c17.1-12.5,59.6-43,113.9,5c-25-5-32.1,21-51.8,27.6"/>
		<path style="stroke-width:32;" class="an20" d="M282.3,431.8c17.2,0,66.5-3,72.6,2.2"/>
</g>
<g class="st6">
		<path style="stroke-width:51;" class="an21" d="M513.3,207.7c24.7,21.1,67.4,12.5,84.1,9c-122.1,29-155.9,64.4-131.8,92.3c40.6,47,0.8-23.8,135.8-35.2
		c-62.3,3-113.1,146.3-141.3,146.3"/>
		<path style="stroke-width:43;" class="an22" d="M576.7,342.5c-27.6,20.4-30.5,133.7,103.8,83.6c-24.4-12.6-33.1-42.9-33.1-42.9"/>
</g>
</g>
	
<path class="st21" data-aos="fade" data-aos-delay="1600"  d="M94.5,552.3c-0.4-0.8-0.7-1.5-1.1-2.3c-0.6-1.3-2.6-7.5-2.6-8.7c0-0.2,0.2-0.5,0.4-0.5c0.2,0,0.4,0.5,0.5,0.6
	c0.5,1.1,1,2.2,1.6,3.3c0.7,1.4,1.6,2.9,2.6,4.2c1.3-4,2-8,2.5-12.2h-2.5l-2.1,2.1l-3.9-3.8l1.8-1.2c1.4-5.1,2.8-10.2,4.2-15.3h-6.1
	l-1,0.7l-1.6-3c0.6,0.1,1.3,0.2,1.9,0.2c2.2,0.1,4.5,0,6.7,0c0.2-0.2,2.1-2.3,2.3-2.3c0.5,0,4.5,3.3,4.5,3.9c0,0.7-1.7,1.6-2.3,1.9
	l-4.3,14.6h2c0.2-0.3,1.8-2.2,2.2-2.2c0.7,0,3.1,2.3,3.7,2.9c0.2,0.2,0.7,0.6,0.7,1c0,0.5-1.3,1.1-1.7,1.3c-0.5,3.5-1.6,9.9-2.9,13
	l-1,2.4c1.1,1.2,3,2.2,4.5,2.9c3.4,1.7,8.6,1.8,12.4,1.8c5.4,0,10.7-0.5,16-1.3c0.1-0.1,0.4,0.1,0.4,0.2c0,0.3-1.2,1.2-1.4,1.4
	c-1.5,1.3-2.3,2.8-2.7,4.7c-3.5,0.1-7,0.2-10.6,0.2c-5.5,0-11.7,0.2-16.6-2.9c-0.9-0.6-2-1.4-2.7-2.2l-1.4-1.6
	c-2.5,2.8-4.9,4.7-8.1,6.6c-0.3,0.2-2.1,1.3-2.3,1.3c-0.1-0.2-0.1-0.4-0.1-0.6C89.7,560.3,92.8,556.6,94.5,552.3z M112,519.2H106
	l-1,0.8l-1.8-3c0.7,0.1,1.4,0.2,2.1,0.2h6.8v-3.8c0-0.7-0.1-1.3-0.1-1.9c-0.1-0.9-0.7-1.9-0.7-2.4c0-0.2,0.2-0.3,0.4-0.3
	c0.3,0,0.6,0.1,0.9,0.1c1.3,0.2,2.4,0.5,3.5,0.9c0.4,0.1,2.4,0.7,2.4,1.1c0,0.5-1.5,1.5-1.8,1.7v4.8h3.9c0.2-0.2,1.5-2.1,2.1-2.1
	c0.7,0,3.2,1.7,3.6,2c0.3,0.2,1.3,0.7,1.3,1.1c0,0.7-1.3,1.4-1.7,1.7v4.4h0.9c0.1-0.1,2.3-2.3,2.7-2.3c0.4,0,3.9,3.3,3.9,3.8
	c0,0.4-0.7,0.5-0.9,0.5H126v4.2c0,1.4,0.2,2.8,0.3,4.2c-1.7,0.7-3.6,1-5.3,1.2l0.2-2.4h-4.5l0.1,5.2l4,0.1c0.2-0.2,2-2.1,2.5-2.1
	c0.6,0,2.9,2.2,3.2,2.5c0.2,0.2,0.5,0.6,0.5,1c0,0.4-0.2,0.7-0.7,0.7h-9.7v5.3h5.9c0.1,0,2.4-2.6,2.7-2.6c0.5,0,3.2,3,3.5,3.4
	c0.2,0.2,0.5,0.5,0.5,0.8s-0.3,0.5-0.6,0.5h-12v1.8c0,1.6,0.2,3.4,0.4,5c-1.7,0.5-3.5,1-5.3,1.2c0.2-2.6,0.4-5.3,0.3-8h-6.7
	l-0.9,0.7l-1.8-3c0.9,0.1,1.9,0.2,2.7,0.2h6.7v-5.3h-5.3l-0.8,0.7l-1.8-2.9c2.6,0.3,5.4,0.3,8,0.2v-5.2h-5.8l-0.9,0.8l-1.7-3.2
	c1,0.1,2.1,0.3,3.2,0.3h5.3v-5h-8.8l-1,0.6l-1.8-2.9c0.8,0.1,1.8,0.2,2.6,0.2h8.9V519.2z M121.2,524.3v-5.1h-4.5l0.1,5.1H121.2z
	 M121.2,531.3v-4.9l-4.5-0.1l-0.1,5H121.2z M156.5,548.5l-1.2,0.9l-2.1-3.3c1.3,0.1,2.6,0.3,3.8,0.3h15.6v-1.2
	c0-1.1-0.1-1.8-0.5-2.9c-0.5,0.2-3.9,2-4.5,2c-0.2,0-0.2-0.1-0.2-0.3s0.1-0.3,0.2-0.4c1.3-1,2.7-1.8,3.9-3c1.8-2,3.4-4.5,4.2-6.7
	c-0.7-0.7-2.6-2.2-3.9-3.2c0-0.2,0.1-0.2,0.2-0.4c1.4,0.4,3.1,0.7,4.2,1c0.1-0.9,0.2-1.8,0.2-2.7c-0.2,0-0.9-0.1-1.3,0
	c-0.5,0.5-0.7,0.6-1,1.1c-0.2-0.1-2.5-3.4-2.3-3.4c0.2,0,0.1-0.2,0.4-0.2c1.4,0.1,2.9,0.3,3,0.4h9.8c0.2-0.3,1.2-1.5,1.6-1.5
	c0.5,0,4.4,2.4,4.4,2.9c0,0.4-1.4,1.3-1.7,1.5v6.7c0,2-0.1,2.5,2,2.5c0.4,0,1,0,1.4-0.2c1.7-1,1.9-7.1,2.8-7.1
	c0.2,0,0.4,0.1,0.4,0.3c-0.1,1.1-0.1,2.2-0.1,3.3c0,2.3,0.4,3,2.6,3.9c-1.7,3.9-3.5,4-7.6,4c-1.8,0-4.8,0.1-5.5-2
	c-0.2-0.6-0.2-1.6-0.2-2.2v-10.1h-4.3c-0.1,1.1-0.7,3.2-0.8,4.4c2.1,0.8,3.6,1.7,3.6,4.4c0,1.1-0.3,2.1-1.6,2.1
	c-1.5,0-2.7-2.8-3.2-3.6c-1.6,3.7-4.5,5-6.2,6.3c0.7,0.1,5.9,1,5.9,1.8c0,0.3-1.1,1.1-1.3,1.3v1.3h13.3c0.3-0.3,2.4-2.8,2.9-2.8
	c0.4,0,1.1,0.7,1.3,1c0.5,0.5,2.9,2.6,2.9,3.3c0,0.5-0.5,0.7-1,0.7h-16.6c3.3,3.4,5.5,5.1,9.8,7.2c2.7,1.3,5.5,2.4,8.3,3.5
	c0.2,0.1,0.4,0.2,0.4,0.5c0,0.4-1.4,0.5-1.8,0.6c-2.8,0.5-3.3,0.9-5.3,2.9c-6.6-4.2-10.2-7.6-13.2-14.6h-1V559
	c0,1.6,0.2,3.2,0.3,4.7c-1.7,0.7-3.5,1.1-5.2,1.6c0.1-1.6,0.3-3.2,0.3-4.7v-9.4c-4.9,4.5-8.5,7.6-14.6,10.1c-1,0.4-4.5,1.8-5.4,1.8
	c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.2-0.4,0.4-0.5c5.7-3.5,11.8-8.6,15.9-13.8H156.5z M162.9,515.6h7.2c0.2-0.3,2.1-2.4,2.5-2.4
	c0.5,0,3.5,3.3,3.5,3.9c0,0.5-0.5,0.6-1,0.6h-13.5c-2.5,3.2-4.8,5.7-7.9,8.2c-0.2,0.1-0.5,0.4-0.7,0.4c-0.2,0-0.2-0.2-0.2-0.4
	c0-0.4,1.7-2.9,1.9-3.4c2.1-3.4,5.2-10.6,5.5-14.5c0.1-0.1,0.3-0.1,0.4-0.1c1,0,6.3,2.1,6.3,2.9c0,0.5-1.4,1-1.8,1.2
	C164.5,513.2,163.7,514.4,162.9,515.6z M161.8,537.2l0.1-8h-3.3l-1.2,1l-2-3.3c1.3,0.1,2.6,0.3,3.8,0.3h7.9c0.4-0.4,1.7-1.4,2.3-1.4
	c0.8,0,2.9,2.2,2.9,2.9c0,0.5-0.5,0.5-0.9,0.5h-4.4c-0.2,0.4-0.5,0.7-0.8,0.9v6.4c1.7-0.4,2.1-0.5,3.8-0.8c0.4-0.1,0.9-0.2,1.3-0.2
	c0.1,0,0.4,0.1,0.4,0.2c0,0.3-0.1,0.4-0.4,0.5c-2,1.1-2.3,1.6-4.4,2.4c-3.4,1.4-6.6,3.1-9.7,4.9l-2.6-5.2
	C157.1,537.9,159.1,537.7,161.8,537.2z M164.5,518c0.2,0,0.4,0.1,0.5,0.1c1.8,0.7,4.6,2,4.6,4.3c0,1.4-1.1,2.4-2.4,2.4
	c-2,0-2-2.7-2-3.9c-0.1-1-1-2-1-2.6C164.2,518.1,164.3,518,164.5,518z M182.3,515.9h8.7c0.3-0.4,1.7-2.4,2.4-2.4
	c0.7,0,3.8,3,3.8,3.9c0,0.6-1,0.7-1.4,0.7h-15c-1.8,2.3-2.8,3.3-5.2,5.2c-0.3,0.2-1.3,1.1-1.6,1.1c-0.2,0-0.3-0.2-0.3-0.4
	c0-0.2,0.6-1,0.7-1.3c2.7-4.4,4.8-9.4,5.5-14.5c0.1,0,0.2-0.1,0.3-0.1c0.9,0,6.2,2,6.2,3.1c0,0.5-1.6,1-1.9,1.1
	C183.8,513.6,183,514.7,182.3,515.9z M184.8,518.3c1,0,3.1,1.6,3.8,2.2c0.5,0.5,0.8,1.1,0.8,1.8c0,1.1-1,2.3-2.2,2.3
	c-0.5,0-0.9-0.2-1.2-0.5c-0.9-0.8-0.8-2.3-0.8-3.3c-0.1-0.8-0.8-2.1-0.8-2.3C184.4,518.4,184.6,518.3,184.8,518.3z M238.4,546.3
	h-11.6l-0.9,0.9l-2-3.4c1.1,0.1,2.3,0.3,3.3,0.3h11.1v-5.6c0-1.4-0.2-2.9-0.5-4.2c-2.2,0.2-4.5,0.4-6.7,0.7
	c-1.3,0.1-2.6,0.3-3.9,0.6c-0.7,0.2-1.4,0.4-2,0.8l-0.9,0.7l-3.2-6.5c1-0.1,2.1-0.4,3.2-0.7c3-0.8,10.4-8.9,11.7-11.9l0.7-1.4h-14.6
	l-0.9,1l-2-3.4c1.1,0.1,2.3,0.3,3.3,0.3h32.1c0.4-0.4,2.6-2.7,3.4-2.7c0.9,0,2.9,2.1,3.5,2.7c0.4,0.3,1,0.9,1,1.4
	c0,0.5-0.5,0.7-1,0.7h-21.2c0.8,0.4,3.8,1.8,3.8,2.6c0,0.7-2,1-2.4,1.1c-2,1.7-3.9,3.5-5.9,5.1c-2.1,1.8-4.4,3.3-6.7,4.9
	c4.1,0.2,8.1,0.3,12.2,0.4c4.1,0.1,8.3,0.2,12.4,0.3c-2.1-3.5-2.7-4.7-5.9-7.4c-0.2-0.2-0.5-0.5-0.5-0.8c0-0.2,0.1-0.4,0.3-0.4
	c0.4,0,1,0.3,1.3,0.5c3.8,2,8.3,4.8,10.9,8.2c0.8,1.2,1.7,2.6,1.7,4.1c0,1.6-0.9,2.8-2.6,2.8c-0.9,0-1.8-0.5-2.3-1.3
	c-0.7-1-1.4-2.8-1.9-3.9c-4.9,0.4-9.7,0.9-14.6,1.1l3.8,1.5c0.3,0.1,1.3,0.5,1.3,1c0,0.8-1,1.3-1.5,1.7v5.8h8.2
	c0.4-0.4,2.4-2.5,3-2.5c0.7,0,4.3,3.1,4.3,4c0,0.5-0.5,0.7-1,0.7h-14.6v12.2H256c0.4-0.4,2.6-2.6,3.2-2.6c0.9,0,2.9,2.1,3.5,2.7
	c0.4,0.3,1,0.9,1,1.4c0,0.5-0.4,0.7-0.9,0.7h-41l-1,0.9l-2-3.4c1.1,0.1,2.3,0.3,3.3,0.3h16.4V546.3z M289.1,535l-2.4-3.2
	c1.1,0.1,2.3,0.2,3.4,0.2h14.6v-4.8h-9.8l-1.1,0.8l-2.4-3.2c1.1,0.1,2.3,0.4,3.3,0.4h10v-4.6h-14.3l-1.1,0.8l-2.5-3.2
	c1.1,0.1,2.3,0.2,3.4,0.2h19.6c1.4-2.5,3.1-6,3.6-8.8c0.1-0.4,0.1-1.6,0.7-1.6c0.9,0,6.3,2,6.3,2.9c0,0.2-1.6,0.8-1.8,1
	c-2.1,2.1-4.2,4.3-6.3,6.4h8.1c0.4-0.4,2-2.9,2.9-2.9c0.5,0,1.3,0.7,1.6,1c0.4,0.4,2.9,2.4,2.9,3.2c0,0.6-0.8,0.8-1.3,0.8h-16.2
	l-0.5,0.7v3.9h7.5c0.3-0.4,1.7-2.3,2.4-2.3c0.5,0,1,0.4,1.3,0.7c0.5,0.5,2.7,2.1,2.7,3c0,0.5-0.4,0.7-0.8,0.7h-13.1v4.8h10.1
	c0.4-0.4,1.8-2.3,2.5-2.3c0.8,0,4.2,2.9,4.2,3.8c0,0.5-0.7,0.7-1.1,0.7h-10c0.8,0.3,4.7,1.7,4.7,2.8c0,0.4-1.3,0.7-1.5,0.8
	c-1.7,1.3-3.2,2.7-4.7,4.1h8.8c0.2-0.3,2-2.7,2.6-2.7c0.4,0,1.1,0.6,1.3,0.8c0.4,0.4,2.9,2.3,2.9,3.2c0,0.5-0.6,0.7-1,0.7h-41.1
	l-1.1,0.8l-2.4-3.2c1.1,0.1,2.3,0.3,3.3,0.3h24.3c1.5-2.3,2.7-5,3.3-7.7h-24.6L289.1,535z M292.5,559.3V550c0-0.8-0.1-2.5-0.4-3.3
	c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.2,0.2-0.2,0.4-0.2c0.6,0,4.1,1.2,4.6,1.4l1.5,1h18.1c0.2-0.2,1.5-2,2-2c0.4,0,1.4,0.6,1.7,0.8
	c0.5,0.3,3.5,1.7,3.5,2.7c0,0.7-1.2,1.3-1.7,1.6l0.1,12.2l-5.5,1.3l0.3-3.5h-19.3v2.3c-1.8,0.6-3.6,1.1-5.5,1.3
	C292.2,563.1,292.5,561.2,292.5,559.3z M296.9,538.6c-0.5-1.3-2.4-2.9-3.4-3.8c0-0.1,0.2-0.2,0.3-0.2c1.1-0.2,3.5,0.4,4.7,0.7
	c1.5,0.4,3.2,1.8,3.5,3.4c0.2,1.3-0.4,2.7-1.8,3C297.9,542,297.5,540.2,296.9,538.6z M298.5,516.7c-0.5-0.8-0.6-1.7-0.8-2.6
	c-0.4-1.8-1.1-3.3-2.5-4.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.2,0.2-0.2,0.3-0.2c1.3,0,3.3,0.7,4.5,1.3c1.2,0.5,2.7,1.3,3.3,2.5
	c0.2,0.5,0.6,1.4,0.6,2c0,1.7-1.3,3-3,3C299.8,517.8,299,517.5,298.5,516.7z M316.8,559.2v-9H298l-0.4,0.5v8.5H316.8z M375.1,530.2
	l-1.1,0.8l-2.1-3.3c1.3,0.1,2.6,0.4,3.9,0.4h33.7c0.2-0.2,3.1-2.9,3.6-2.9c0.4,0,1,0.6,1.3,0.8l2.8,2.6c0.3,0.2,0.6,0.5,0.6,0.9
	c0,0.5-0.4,0.7-0.8,0.7h-16.6c0.7,0.2,2.9,0.9,2.9,1.7c0,0.5-1.3,1.4-1.7,1.7v21.6c0,0.4,0,1,0.2,1.3c0.5,0.8,6,0.5,7.2,0.5
	c0.7,0,1.8,0,2.4-0.5c1.4-1.1,2.7-7.1,3.2-8.8c0-0.2,0.1-0.4,0.4-0.4c0.1,0,0.3,0.1,0.3,0.2c0,1.6-0.1,3.2-0.1,4.7
	c0,1.4,0.3,3.5,1.7,4.1l1.3,0.5c-0.2,0.8-0.5,1.6-0.7,2.4c-0.8,2.6-4,3.1-6.4,3.1c-1.4,0-2.9,0-4.3,0c-2.2,0-9,0.3-9.9-2.1
	c-0.2-0.7-0.4-1.6-0.4-2.3v-27.8h-6.3c0.8,0.3,3.4,1.2,3.4,2.2c0,0.5-1.3,1.3-1.7,1.6c-1,10.3-0.8,19.6-10.7,25.7
	c-1.2,0.8-7.6,4.5-8.7,4.5c-0.2,0-0.4-0.2-0.4-0.4c0-0.3,0.4-0.5,0.6-0.7c0.7-0.5,1.4-1,2-1.5c3.2-2.4,5.5-4.9,7.5-8.4
	c3.3-5.8,4-13.1,4-19.7c0-1.1-0.1-2.1-0.2-3.2H375.1z M406.6,510.1c0.8,0,3.7,3.3,3.9,3.5c0.3,0.3,0.7,0.8,0.7,1.2
	c0,0.4-0.5,0.6-0.9,0.6h-30l-1.1,0.8l-2.1-3.3c1.3,0.1,2.6,0.4,3.9,0.4l21.7,0.1C402.9,513.2,406,510.1,406.6,510.1z M481.5,534
	c-1,0.5-2,1.4-2.9,2.1c-2.6-2.6-4.4-4.8-6-8.2c-0.8-1.6-1.7-3.3-2.3-5.1c-0.8-2.3-1.3-4.8-1.8-7.2c-1.5,6.1-5.7,13.7-10.3,18
	c1.1,0.8,2.3,1.8,2.3,2.1c0,0.6-1.1,1.4-1.4,1.8c-0.2,2.1-0.5,4.2-0.8,6.2c-0.6,4.8-1.4,13.9-5.8,16.8c-1.2,0.8-2.6,1.3-3.9,1.6
	c-0.1-1.2-0.2-2.1-0.6-3.3c-0.7-1.6-3.1-2.1-3.1-2.7c0-0.2,0.2-0.4,0.5-0.4c1.1,0.2,2.4,0.4,3.6,0.4c0.5,0,1.2,0,1.7-0.4
	c2.3-1.7,3.5-16.3,3.8-19.1h-4.7c-1.1,6.3-2.7,14.4-6.3,19.7c-1,1.4-2,2.9-3.2,4.2c-0.3,0.4-2.4,3-2.8,3c-0.2,0-0.4-0.2-0.4-0.4
	c0-0.4,1.1-2.4,1.4-2.9c1.7-3.3,2.9-7,4-10.6c1.7-5.8,2.4-11.8,2.9-17.8c0.2-1.6,0.3-3.2,0.3-4.8c0-0.5,0-1-0.1-1.5h-5.5l-1.1,0.7
	l-2-3.2c1.2,0.1,2.4,0.4,3.6,0.4h12.3c0.3-0.4,2.4-2.6,2.9-2.6c0.4,0,3.9,3.3,3.9,4c0,0.5-0.5,0.7-0.9,0.7h-7.8
	c0.4,0.2,1.4,0.7,1.4,1.3c0,0.5-1.1,1.1-1.5,1.3c-0.2,2.1-0.5,4.2-0.8,6.4h3.8c0.2-0.3,1.8-2.2,2.3-2.2c0.1,0,0.4,0.1,0.8,0.4
	c2.4-3.4,4.1-7.3,5.1-10.8c1-3.2,1.8-6.8,1.8-10.2c0-0.7-0.2-1.3-0.2-1.9c0-0.4,0.2-0.4,0.5-0.4c1.2,0,7.6,1.8,7.6,2.5
	c0,0.5-1.7,1.6-2.1,1.8c3.1,7,7,12.9,13.3,17.5c0.3,0.2,1.7,1.1,1.7,1.4C484.5,533.1,482.1,533.7,481.5,534z M450.2,520.7
	c-1.1,0-2.1-0.7-2.4-1.7c-0.2-0.6-0.3-1.3-0.4-1.9c-0.2-1.7-0.6-3.5-1.4-5c-0.5-1-1.2-1.9-1.8-2.9c-0.3-0.3,0.1-0.6,0.4-0.6
	c0.4,0,2.3,1.3,2.7,1.5c2.7,1.5,5.4,3.9,5.7,7.2C453,519.1,452.3,520.7,450.2,520.7z M474.2,562.2c-0.9,0-1.8-0.3-2.4-1
	c-0.6-0.8-1.1-1.6-1.5-2.5c-1.6-3.2-4.8-7.8-7.6-9.9c-0.2-0.2-0.8-0.6-0.8-0.9c0-0.2,0.2-0.4,0.4-0.4c0.4,0,1,0.4,1.5,0.5
	c3.6,1.4,12.9,6.6,12.9,11.4C476.7,561,475.9,562.2,474.2,562.2z M471.4,542.4c-0.8,0-1.7-0.3-2.1-1c-1.1-1.6-1.3-4.6-2.5-6.6
	c-0.3-0.5-1.6-2.1-1.6-2.6c0-0.2,0.1-0.2,0.2-0.2c0.5,0,2.1,0.9,2.6,1.1c2.3,1.1,5.9,3.6,5.9,6.4C473.8,540.9,473,542.4,471.4,542.4
	z M505.3,525.9l-1,0.8l-2-3.2c1.1,0.1,2.2,0.3,3.3,0.3h10.5c0.2-0.3,1.9-2.7,2.3-2.7c0.4,0,1,0.4,1.3,0.5l3.5,2c0.3,0.2,1,0.7,1,1.1
	c0,0.7-1.8,1.4-2.3,1.6c-1.7,2-3.3,3.9-4.9,5.8c0.5,0.3,2.2,1,2.2,1.6c0,0.4-1.4,1.4-1.8,1.5v3.2c2.1-2.1,5.5-6.5,6.2-9.3
	c0.1-0.1,0.1-0.1,0.2-0.1c0.7,0,4.9,3.5,4.9,4.4c0,0.7-2,0.7-2.4,0.7c-2.2,1.6-4.5,3.2-6.7,4.8c1.5,0.5,5.1,1.8,5.7,3.6
	c0.2,0.7,0.4,1.5,0.4,2.2c0,1.6-1.2,2.9-2.7,2.9c-3.6,0-2.3-3.8-3.3-6.2c-0.3-0.8-1.5-1.8-2.1-2.3v16.9c0,2.1,0.2,4.2,0.4,6.3
	c-1.8,0.4-3.6,0.8-5.5,1.3c0.1-2.2,0.4-4.9,0.4-7.1c0-6.7,0-13.4,0-20c-1.3,1.1-9.2,7.5-10,7.5c-0.2-0.4-0.1-0.5,0-0.7
	c4.8-5.7,9.2-10.8,12.8-17.4H505.3z M510,508.6c0.6,0,2,0.4,2.6,0.5c2.2,0.4,6.4,2.3,6.4,4.9c0,1.7-0.2,2.9-1.7,3.9
	c-0.4,0.2-0.8,0.4-1.3,0.4c-3.7,0-2.6-4.9-4.5-7.3c-0.4-0.5-0.8-1-1.4-1.4c-0.2-0.1-0.6-0.4-0.6-0.6
	C509.6,508.7,509.7,508.6,510,508.6z M544.3,514.4c0.5,0,1.6,0.7,2,1c0.7,0.4,3.3,1.7,3.3,2.7c0,0.5-1.4,1.6-1.7,1.8
	c-0.1,6.5-0.1,12.9-0.4,19.4c-0.2,4.2-0.4,8.8-1.3,12.9c-0.7,3-1.8,6.1-4.2,8.3c-1.4,1.4-3.7,2-5.6,2.4c-0.1-1.7-0.1-3.3-1.3-4.7
	c-0.9-1-1.4-1.3-2.6-1.8c-0.2-0.1-0.4-0.2-0.4-0.4c0-0.2,0.3-0.4,0.5-0.4c1.3,0.1,2.5,0.3,3.7,0.3c1.4,0,2.4-0.3,3.3-1.5
	c3.1-4.3,3.1-23,3.1-28.8c0-2.1-0.1-4.3-0.1-6.5h-6.2c0.5,0.1,2.1,0.5,2.1,1.3c0,0.7-0.8,1.2-1.4,1.4c-0.1,3.4-0.1,6.7-0.3,10.1
	c-0.6,10.8-1.3,17.8-9.2,25.8c-2.1,2.1-4.6,3.9-7.3,5.4c-0.4,0.2-1.5,1-1.9,1c-0.2,0-0.2-0.2-0.2-0.3c0-0.2,0.1-0.3,0.2-0.4
	c3.2-2.5,5.5-6,7.8-9.3c1.8-2.6,3-5.5,3.7-8.5c1.6-6.4,2-13.2,2-19.8c0-2.3,0-4.5,0-6.7h-5.1l-1,0.8l-2-3.2c1.1,0.1,2.2,0.3,3.3,0.3
	h15C542.5,516.4,543.8,514.4,544.3,514.4z M571.9,550.2c-1.6,0-2.4-1.2-2.4-2.7c0-1.9,1.3-3.5,2.1-5.1c1-1.7,1.7-3.6,2.2-5.5
	c0.3-1.4,0.7-2.7,0.8-4.1c0.1-0.7,0.2-2.5,0.4-3c0.1-0.2,0.2-0.4,0.4-0.4c0.2,0,0.5,1.5,0.6,1.7c0.7,2.4,0.8,6.6,0.8,9.1
	C576.8,543.3,576,550.2,571.9,550.2z M582,524.3c0.7,0,2.3,0.6,3,0.8c0.8,0.2,3.8,1.1,3.8,2.3c0,0.5-1.3,1.1-1.6,1.3v26.7
	c0,0.5,0.1,1.2,0.4,1.6c0.4,0.5,3.6,0.5,4.2,0.5c1,0,5.5,0,6.4-0.4c0.6-0.4,1-0.8,1.3-1.4c0.9-2,1.4-4.3,1.8-6.4
	c0.1-0.5,0.2-2.6,0.8-2.6c0.3,0,0.4,0.4,0.4,0.6c-0.1,1.7-0.2,3.3-0.2,4.9c0,2.2,1,3.7,2.9,4.8c-0.3,1.1-0.7,2.3-1.2,3.3
	c-0.5,1-1.6,1.5-2.6,1.8c-2.2,0.5-5.2,0.5-7.6,0.5h-1.1c-2.1,0-6.1,0.1-8-0.4c-2.2-0.5-2.6-1.8-2.6-3.9v-29.5c0-0.8-0.1-1.7-0.2-2.6
	c-0.1-0.5-0.6-1.1-0.6-1.6C581.3,524.3,581.8,524.3,582,524.3z M594.5,526.3c-1.4,0-2.4-1.1-2.6-2.5c-0.2-1.2-0.2-2.6-0.2-3.8
	c-0.1-3.4-1.2-7.6-3.2-10.4c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.1-0.4,0.2-0.4c1.2,0,5.7,4.8,6.6,5.9c2,2.3,2.5,4.1,2.5,7
	C597.6,523.7,597,526.3,594.5,526.3z M613.4,548.3c-0.4,1.6-1.5,2.7-3.2,2.7c-1.3,0-2.1-0.9-2.4-2.1c-0.5-1.7-0.2-3.5-0.3-5.2
	c-0.3-5.2-1-10.5-3.2-15.3c-0.1-0.1-0.1-0.3-0.1-0.5s0.1-0.4,0.2-0.4c0.4,0,1.5,1.4,1.8,1.7c2.3,2.7,4.2,5.6,5.5,8.9
	c0.9,2.3,1.8,4.8,1.8,7.3C613.5,546.4,613.6,547.4,613.4,548.3z"/>
</svg>

    <div class="txt">
    <div class="title1" data-aos="fade" data-aos-delay="100" >24～93坪<span></span>挑高4米2</div>
    <div class="title2" data-aos="fade" data-aos-delay="300" >即將完工</div>
    </div>
</div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/function.scss';
.l{fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st1{clip-path:url(#svg9);}
.st2{clip-path:url(#svg7);}
.st3{clip-path:url(#svg5);}
.st4{clip-path:url(#svg3);}
.st5{clip-path:url(#xsvg1);}
.st6{clip-path:url(#svg1);}
.st3{clip-path:url(#svg5);}
.st21{fill:#C30D23;transform: scale(0.1);transform-origin: 50% 50%;}
.an1	{stroke-dasharray:	126	;stroke-dashoffset:	126	;animation: letterDraw	0.189s 	0s linear forwards;}	
.an2	{stroke-dasharray:	64	;stroke-dashoffset:	64	;animation: letterDraw	0.096s 	0.189s linear forwards;}	
.an3	{stroke-dasharray:	126	;stroke-dashoffset:	126	;animation: letterDraw	0.189s 	0.285s linear forwards;}	
.an4	{stroke-dasharray:	58	;stroke-dashoffset:	58	;animation: letterDraw	0.087s 	0.474s linear forwards;}	
.an5	{stroke-dasharray:	102	;stroke-dashoffset:	102	;animation: letterDraw	0.153s 	0.561s linear forwards;}	
.an6	{stroke-dasharray:	163	;stroke-dashoffset:	163	;animation: letterDraw	0.2445s 	0.714s linear forwards;}	
.an7	{stroke-dasharray:	90	;stroke-dashoffset:	90	;animation: letterDraw	0.135s 	0.9585s linear forwards;}	
.an8	{stroke-dasharray:	21	;stroke-dashoffset:	21	;animation: letterDraw	0.0315s 	1.0935s linear forwards;}	
.an9	{stroke-dasharray:	66	;stroke-dashoffset:	66	;animation: letterDraw	0.099s 	1.125s linear forwards;}	
.an10	{stroke-dasharray:	95	;stroke-dashoffset:	95	;animation: letterDraw	0.1425s 	1.224s linear forwards;}	
.an11	{stroke-dasharray:	53	;stroke-dashoffset:	53	;animation: letterDraw	0.0795s 	1.3665s linear forwards;}	
.an12	{stroke-dasharray:	44	;stroke-dashoffset:	44	;animation: letterDraw	0.066s 	1.446s linear forwards;}	
.an13	{stroke-dasharray:	122	;stroke-dashoffset:	122	;animation: letterDraw	0.183s 	1.512s linear forwards;}	
.an14	{stroke-dasharray:	54	;stroke-dashoffset:	54	;animation: letterDraw	0.081s 	1.695s linear forwards;}	
.an15	{stroke-dasharray:	36	;stroke-dashoffset:	36	;animation: letterDraw	0.054s 	1.776s linear forwards;}	
.an16	{stroke-dasharray:	53	;stroke-dashoffset:	53	;animation: letterDraw	0.0795s 	1.83s linear forwards;}	
.an17	{stroke-dasharray:	190	;stroke-dashoffset:	190	;animation: letterDraw	0.285s 	1.9095s linear forwards;}	
.an18	{stroke-dasharray:	32	;stroke-dashoffset:	32	;animation: letterDraw	0.048s 	2.1945s linear forwards;}	
.an19	{stroke-dasharray:	187	;stroke-dashoffset:	187	;animation: letterDraw	0.2805s 	2.2425s linear forwards;}	
.an20	{stroke-dasharray:	74	;stroke-dashoffset:	74	;animation: letterDraw	0.111s 	2.523s linear forwards;}	
.an21	{stroke-dasharray:	639	;stroke-dashoffset:	639	;animation: letterDraw	0.9585s 	2.634s linear forwards;}	
.an22	{stroke-dasharray:	254	;stroke-dashoffset:	254	;animation: letterDraw	0.381s 	3.5925s linear forwards;}	

@keyframes letterDraw {
  to { stroke-dashoffset: 0;}
}
.t1{transform: scale(0.1);transform-origin: 50% 50%;}
.t2{transform: scale(0.1);transform-origin: 50% 50%;}

.section1 {
  width: 100%;
  height: 100vh;
  min-height: size(900);
  max-height: size(1080);
  position: relative;
  margin: 0;
 // background: url("./s1/bg.jpg") center;
  background-size: cover;
  &::before{content: "";width:100%;background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 50%,rgba(0,0,0,0) 100%);
  height: size(1030);
  position: absolute;
  bottom:size(1030 * -.5);left: 0;
  }
}

.img0 {
  @include img_c_pc(1920,0);opacity:0.5;width: 100%;
  z-index:1;
  top: size-m(0);
}
.bg {
  @include img_c_pc(1920,520);width: 100%;
 top: calc(50% + (520 - 540) * 100vw / 1920);
}
.tree{
  @include img_r_pc(1515,322,0);
 top: calc(50% + (322 - 540) * 100vw / 1920);
  transform:translateX(5%);
  img{width: 100%;
  animation: tree	5s infinite alternate;
  transform: skewY(-2deg);
  transform-origin: 100% 50%;}
}
.b{
  @include img_r_pc(759,163,100);
 top: calc(50% + (163 - 540) * 100vw / 1920);
}
.m{
  @include img_l_pc(865,70,115);
 top: calc(50% + (70 - 540) * 100vw / 1920);
}
.logo{
  @include img_l_pc(700,215,218);
 top: calc(50% + (215 - 540) * 100vw / 1920);
  z-index: 2;
}

@keyframes tree {
  to { transform: skewY(0);}
}
.txt{
  @include img_r_pc(1100, 465,7);
 top: calc(50% + (465 - 540) * 100vw / 1920);
  font-size:size(39);
  color: #fff;
  font-weight: 600;
  line-height: 1.45;
  z-index: 2;
  letter-spacing: 0.15em;
  .title1{
	  transform: scaleX(5);
  font-size:1.4em;
    span{display: inline-block;width: .8em;height: .8em;position: relative;
    margin: 0 0.4em;
&::after,
&::before{content: "";width: 100%;height: 2px;background: currentColor;display: block;position: absolute;top: calc(50% - 1px);}
&::after{transform: rotate(-45deg);}
&::before{transform: rotate(45deg);}

    }
  }
  .title2{
	  transform: scaleX(5);
  letter-spacing: 0.25em;
&::after,
&::before{content: "";width: 5em;height: 2px;background: currentColor;
vertical-align: middle;margin: 0 0.4em 0.2em 0.4em;
display: inline-block;}
}
}
/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section1 {
    min-height: sizem(604);
    max-height: sizem(750);
    height: calc(100vh - 63px);
    height: sizem(604);
  &::before{
	    height: sizem(1030);
  bottom:sizem(1030 * -.7);
  }
}
.bg {
 top: sizem(365);
}
.tree{
  @include img_r_m(520,420,-230);
  }
.tree2{
  @include img_l_m(520,-120,-800);z-index: 5;
  transform:translateX(-5%) ;
  img{width: 100%;
  animation: tree2	5s infinite alternate;
  transform: skewY(-2deg)scaleX(-1);
  transform-origin: 100% 50%;}
}
@keyframes tree2 {
  to { transform: skewY(0)scaleX(-1);}
}
.b{
  @include img_r_m(113,25,23);
}
.m{
  @include img_c_m(310,90);
}
.logo{
  @include img_l_m(251,140,68);
  z-index: 2;
}
.txt{
  @include img_c_m(370,450);
  font-size:sizem(20);
}

}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'
import info from '@/info'
import UIkit from 'uikit';
export default {
  name: 'section1',

  data() {
    return {
      info,
      isPC,
      isMobile,
      isTablet,
      UIkit,
      // blockIndex: 0,
    }
  },

  methods: {},

  mounted() {
    // setTimeout(() => {
    //   this.blockIndex = 1
    // }, 6000)
  },

  created() {},

  computed: {},
}
</script>
